.custom-shape-divider-top-1689726451 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.custom-shape-divider-top-1689726451 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.custom-shape-divider-top-1689726451 .shape-fill {
    fill: #493894;
}

.shape-divider-projects {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.shape-divider-projects svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
    transform: rotateY(180deg);
}

.shape-divider-projects .projects-shape-fill {
    fill: #FFFFFF;
}

.contact-shape-divider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.contact-shape-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
}

.contact-shape-divider .contact-shape-fill {
    fill: #493894;
}